import React from 'react';
import { ReactComponent as LogotypeBeta } from "../../utils/svg/logotype-beta.svg";

import './Home.scss'
import {Link, useNavigate} from "react-router-dom";
function Home() {
  const navigate = useNavigate();

  return (
      <main className="home-page">
        <section className={'main-section'}>
          <picture className={'home-background-img'}>
            <source srcSet={'/media/home-photo.webp'} media={'(max-width: 768px)'} type={'image/webp'}/>
            <source srcSet={'/media/home-photo-2x.webp'} media={'(min-width: 769px)'} type={'image/webp'}/>
            <source srcSet={'/media/home-photo.jpg'} media={'(max-width: 768px)'}/>
            <source srcSet={'/media/home-photo-2x.jpg'} media={'(min-width: 769px)'}/>
            <img src={'/media/home-photo-2x.jpg'} alt="landscape"/>
          </picture>
          <header className={'home-header'}>
            <LogotypeBeta className="logotype-header logotype__light" />
            <nav className={'header-navigation'}>
              <a href={'#about'}>О проекте</a>
              {/* eslint-disable-next-line */}
              <a href="https://pay.cloudtips.ru/p/8e1cd0a6" target="_blank">Поддержать</a>
              {/* eslint-disable-next-line */}
              <a href="https://tally.so/r/3qOaoO" target="_blank">Оставить отзыв</a>
            </nav>
            {/* eslint-disable-next-line */}
            <a className={'saved-button'} href={'#'}>
              <svg width="21" height="23" viewBox="0 0 21 23" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M17.7837 22.1532L15.609 21.9911C15.609 21.9911 17.758 2.84882 17.9423 1.40377C18.1267 -0.0412808 20.404 0.0953508 20.2943 1.50335C20.1847 2.91135 17.7837 22.1532 17.7837 22.1532ZM8.18666 12.376L9.148 2.96693L16.281 2.89282L15 13.74L8.18666 12.376ZM0.852997 12.4987L4.689 9.05051L0.955663 5.47261L7.328 5.0604L6.628 13.997L0.852997 12.4987Z"
                      fill="black"/>
              </svg>
              <span className={'saved-button--label'}>Сохранённые</span>
            </a>
          </header>
          <h1 className={'gradient-title'}>Все походы на Урале<br/>в одном месте</h1>
          <Link className="search-button" to="/hikes" style={{color: "#FFF1D5"}}>
            Нажмите, чтобы начать искать
            <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M12.6723 21.7852L10.8482 18.0274L15.4949 16.0829L17.4521 19.7381C18.7223 22.7102 13.8013 24.4945 12.6723 21.7852ZM5.73024 1.16744C15.9263 -3.01603 22.4396 11.9887 12.4805 16.075C1.57887 20.5501 -4.50839 5.3693 5.73024 1.16744ZM6.56904 3.13829C-0.990776 6.24173 3.49611 17.4493 11.5539 14.1435C18.9086 11.1242 14.1022 0.045368 6.56904 3.13829Z" fill="#FFF1D5"/>
            </svg>
          </Link>
          <div className={'marquee-wrapper'}>
            <div className="marquee">
              <div className="marquee--in">
                <span className="marquee--item">как всё работает</span>
                <span className="marquee--item">как всё устроено</span>
                <span className="marquee--item">всё о сервисе</span>
                <span className="marquee--item">как находятся походы</span>
                <span className="marquee--item">какие источники используются</span>

                <span className="marquee--item">как всё работает</span>
                <span className="marquee--item">как всё устроено</span>
                <span className="marquee--item">всё о сервисе</span>
                <span className="marquee--item">как находятся походы</span>
                <span className="marquee--item">какие источники используются</span>

                <span className="marquee--item">как всё работает</span>
                <span className="marquee--item">как всё устроено</span>
                <span className="marquee--item">всё о сервисе</span>
                <span className="marquee--item">как находятся походы</span>
                <span className="marquee--item">какие источники используются</span>
              </div>
            </div>
          </div>
        </section>
        <section className={'idea-section'}>
          {/* eslint-disable-next-line */}
          <a id={'about'}></a>
          <h2 className={'idea-title'}>Идея</h2>
          <span className={'idea-description description'}>
                    Любители экотуризма используют соцсети для
                    организации походов. Но информация настолько
                    разбросана, что затрудняет поиск для новичков
                    и опытных туристов. Мы создали сервис, где все
                    походы собираются в одном месте.
                </span>
          <picture className={'device-img'}>
            <source srcSet={'/media/iphone.png 2x'} media={'(max-width: 576px)'}
                    type={'image/png'}/>
            <source srcSet={'/media/macbook.png'} media={'(min-width: 577px)'}/>
            <img src={'/media/iphone.png'} alt="device site mockup"/>
          </picture>
        </section>
        <section className={'nlp-section'}>
                <span className={'nlp-description description'}>
                    В своём поисковике мы используем NLP
                    для автоматического сбора и обработки
                    объявлений о походах из соцсетей.
                </span>
          <div className={'nlp-logotype'}>
            <div className={'circles'}>
              <svg height="700" width="700" viewBox="0 0 700 700">
                <circle r="326"/>
                <circle r="231"/>
                <circle r="145"/>
                <circle r="76"/>
              </svg>
            </div>
            <h1 className={'lesnikai-title'}>Lesnik AI</h1>
            <div className={'gradient-background lesnikai-title'}>Lesnik AI</div>
          </div>
        </section>
        <section className={'public-section'}>
                <span
                    className={'public-description description'}>
                    Нейросеть сама ищет походы в открытых
                    пабликах, сортирует, определяет нужную
                    информацию и публикует на нашем сайте.
                </span>
          <picture className={'public-img'}>
            <source srcSet={'/media/public-mobile.png 2x'} media={'(max-width: 576px)'}
                    type={'image/png'}/>
            <source srcSet={'/media/public.png'} media={'(min-width: 577px)'}/>
            <img src={'/media/public-mobile.png'} alt="device site mockup"/>
          </picture>
        </section>
        <section className={'examples-section'}>
                <span className={'examples-description description'}>
                    Поисковик позволит удобно сортировать
                    походы, выбирать, сколько хотите провести
                    в них суток, спросит об оплате
                </span>
          <span className={'examples-description description'}>
                    А нейросеть подберёт самые подходящие
                    вам варианты
                </span>
          <picture className={'examples-img'}>
            <source srcSet={'/media/example-mobile.png 2x'} media={'(max-width: 576px)'}
                    type={'image/png'}/>
            <source srcSet={'/media/example.png'} media={'(min-width: 577px)'}/>
            <img src={'/media/example-mobile.png'} alt="device site mockup"/>
          </picture>
        </section>
        <section className={'trial-section'}>
          <h4 className={'trial-title'}>Попробуйте прямо сейчас!</h4>
          <button className={'try-button'} onClick={() => {navigate('/hikes')}}>Попробовать</button>
          <div className={'gradient-background gradient-background_trial lesnikai-title'}>Lesnik AI</div>
          <span className={'trial-description description'}>
                    Но помните, что сервис ещё находится в разработке. Возможны
                    ошибки и недоработки, приведённые данные могут быть не полными
                    и не точными. Обязательно уточняйте подробности похода
                    у организаторов перед тем как к нему присоединиться.<br/>
                    <Link to={'/privacy-policy'}>Политика конфиденциальности</Link>
                </span>
        </section>
        <footer className={'home-footer'}>
          <div className={'footer-left'}>
            <LogotypeBeta className={'logotype-footer'}/>
            <span className={'rights'}>© 2024 Lesnik Apps. Все права защищены</span>
            <span className={'icon-usage'}>На сайте используются иконки Антона Чикина</span>
          </div>
        </footer>
      </main>
  );
}

export default Home;

import {CustomHeader} from "./CustomHeader";
import React, {forwardRef, useState} from "react";
import DatePicker, {DatePickerProps} from "react-datepicker";
import './CustomCalendar.scss';
import {registerLocale} from "react-datepicker";
import {ru} from 'date-fns/locale/ru';
import {CalendarIcon} from "./CalendarIcon";
import {ArrowIcon} from "../ArrowIcon/ArrowIcon";

registerLocale('ru', ru)

type CustomCalendarProps = Pick<DatePickerProps, "startDate" | "endDate"> & {
    onChange?: (date: [Date | null, Date | null], event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
}

export const CustomCalendar = (props: CustomCalendarProps) => {
    const {startDate, endDate, onChange} = props;
    const [isOpened, setIsOpened] = useState(false)

    interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
        value?: string;
    }

    type ButtonRef = HTMLButtonElement;

    const CalendarInput = forwardRef<ButtonRef, ButtonProps>(
        (props, ref) => {
            const {value, onClick, className} = props;
            // console.log(props)

            return (<button className={`${className} input`} onClick={onClick} ref={ref}>
                <div className="text">
                    <span>{value}</span>
                    <span className={isOpened ? "arrow-rotated" : ""}>
                        <ArrowIcon/>
                    </span>
                </div>
            </button>)
        },
    );


    return (
        <DatePicker
            showIcon
            showPopperArrow={false}
            className={"calendar-input"}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            icon={
                <div className="calendarIconContainer">
                    <CalendarIcon/>
                </div>
            }
            customInput={<CalendarInput/>}
            locale={'ru'}
            open={isOpened}
            onCalendarOpen={() => setIsOpened(true)}
            onCalendarClose={() => setIsOpened(false)}
            onInputClick={() => setIsOpened(!isOpened)}
            shouldCloseOnSelect={false}
            dateFormat={"dd MMM"}
            onChange={onChange}
            calendarClassName={"calendar"}
            weekDayClassName={() => "week-day"}
            renderCustomHeader={CustomHeader}
            renderYearContent={() => <></>}
        >
        </DatePicker>
    );
}

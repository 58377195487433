import "./HikeModal.scss"
import { useGetHikeByIdQuery } from '../../store/commonApi';
import React, {useEffect, useState} from 'react';

interface HikeModalProps {
  hikeId: string;
  handleClose: () => void;
  show: boolean;
}

const pattern = /\[(.*?)]\((.*?)\)/g;
const splitPattern = /\[.*?]\(.*?\)/g;

function formatLink(url: string, text: string) {
  if (!url) {
    return '';
  }
  try {
    new URL(url);
  } catch (err) {
    return url;
  }

  return (
    <a href={url} rel='noopener noreferrer' target='_blank'>
      {text}
    </a>
  );
}

function addLinks(text: string) {
  const splitText = text.split(splitPattern);

  if (splitText.length <= 1) {
    return text;
  }

  // @ts-ignore
  const matches = [...text.matchAll(pattern)];

  if (!matches) return text;

  let arr: (string | JSX.Element)[] = [];
  for (let i = 0; i < splitText.length; i++) {
    arr = matches[i]
      ? [...arr, splitText[i], formatLink(matches[i][2], matches[i][1])]
      : [...arr, splitText[i]];
  }
  return arr;
}

function HikeModal({ hikeId, show, handleClose }: HikeModalProps) {
  const { data: hike, refetch } = useGetHikeByIdQuery(hikeId);
  const [width, ] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;
  const maxLength = isMobile ? 400 : 800;

  useEffect(() => {
    if (hikeId && hikeId !== '') {
      refetch();
    }
  }, [refetch, hikeId]);

  if (!hike) return null;

  return (
      <div className={'hike-modal-wrapper hike-modal_open'}>
        <div className={'backdrop'} onClick={handleClose}></div>
        <button className={'back-button-modal'} onClick={handleClose}>
          <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6854 1.08105L2.31079 11.4557L12.6854 21.8303" stroke="black"
                  strokeWidth="2.65541"/>
            <path d="M2.599 11.4557H27.0946" stroke="black" strokeWidth="2.65541"/>
          </svg>
          <span className={'back-button--label'}>Назад</span>
        </button>
        <div className={'hike-modal'}>
          <div className={'modal-img-wrapper'}>
            <img src={hike.imageUrlHighRes} className={'modal-img'} alt={'hike'}/>
          </div>
          <div className={'modal-content'}>
            <div className={'modal-header-wrapper'}>
              <h5 className={'hike-modal-title'}>{hike.name}</h5>
              <div className={'hike-date'}>
                {hike.date && <time className={'hike-time'}>
                  {(() => {
                    const [year, month, day] = hike.date.split("-");
                    return `${day}.${month}.${year}`;
                  })()}</time>}
                {/*<span className={'hike-duration'}>на 28 суток</span>*/}
              </div>

            </div>
            <span className={'hike-description'}>
              {addLinks(hike.description.slice(0, maxLength).concat(hike.description.length >= maxLength ? "..." : ""))}
            </span>
          </div>
          <div className={'buttons-container'}>
            <button className={'hike-back-button'} onClick={() => handleClose()}>
              Назад
            </button>
            <a className={'hike-post-button'} href={hike?.postUrl} target="_blank" rel="noopener noreferrer">
              К посту
            </a>
          </div>
        </div>
      </div>
  );
}

export default HikeModal;

import React from 'react';
import { Pagination } from 'react-bootstrap';

interface PropsPagination {
  currentPage: number;
  totalPages: number;
}

interface Props {
  pagination: PropsPagination;
  handleChangePage: (page: number) => void;
}

const CustomPagination: React.FC<Props> = ({
  pagination,
  handleChangePage,
}) => {
  const renderPaginationItems = () => {
    const { currentPage, totalPages } = pagination;
    const threshold = 1;

    const items = [];

    items.push(
      <Pagination.Prev
        key='prev'
        onClick={() => handleChangePage(currentPage - 1)}
        disabled={currentPage === 1}
      />,
    );

    if (currentPage > threshold + 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => handleChangePage(1)}>
          1
        </Pagination.Item>,
      );
      if (currentPage > threshold + 2) {
        items.push(<Pagination.Ellipsis key='start-ellipsis' />);
      }
    }

    for (
      let i = Math.max(1, currentPage - threshold);
      i <= Math.min(totalPages, currentPage + threshold);
      i++
    ) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handleChangePage(i)}
        >
          {i}
        </Pagination.Item>,
      );
    }

    if (currentPage < totalPages - threshold) {
      if (currentPage < totalPages - threshold - 1) {
        items.push(<Pagination.Ellipsis key='end-ellipsis' />);
      }
      items.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handleChangePage(totalPages)}
        >
          {totalPages}
        </Pagination.Item>,
      );
    }

    items.push(
      <Pagination.Next
        key='next'
        onClick={() => handleChangePage(currentPage + 1)}
        disabled={currentPage === totalPages}
      />,
    );

    return items;
  };

  return <Pagination>{renderPaginationItems()}</Pagination>;
};

export default CustomPagination;

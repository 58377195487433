import defaultStyles from './styles/default.module.scss'
import defaultGreenStyles from './styles/default-green.module.scss'
import acceptStyles from './styles/accept.module.scss'
import warningStyles from './styles/warning.module.scss'
import {HTMLProps} from "react";

export const typeButton = ["default", "warning", "accept", "default-green"]

export interface CustomButtonProps extends HTMLProps<HTMLButtonElement> {
    type?: 'default' | 'default-green' | "warning" | "accept"
}

export interface Dictionary {
    [key: string]: any
}

export const buttonStyleDictionary: Dictionary = {
    default: defaultStyles,
    warning: warningStyles,
    accept: acceptStyles,
    "default-green": defaultGreenStyles,
}

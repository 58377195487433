interface ArrowIconInterface {
    rotate?: string;
    fill?: string;
}

export const ArrowIcon = (props: ArrowIconInterface) => {
    const {rotate = "0", fill = "black"} = props;

    return (
        <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{rotate: rotate}}>
            <path
                d="M8.5 10C8.8354 10 9.12289 9.87267 9.37204 9.59843L16.6742 1.97845C16.885 1.76298 17 1.48874 17 1.16552C17 0.519099 16.5017 0 15.8596 0C15.5434 0 15.2655 0.13712 15.0451 0.352595L8.5 7.21841L1.9549 0.352595C1.7345 0.13712 1.44701 0 1.14036 0C0.498309 0 0 0.519099 0 1.16552C0 1.48874 0.114994 1.76298 0.325817 1.97845L7.61838 9.59843C7.87711 9.87267 8.1646 10 8.5 10Z"
                fill={fill}
            />
        </svg>
    )
}

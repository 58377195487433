import Select, {GroupBase, Props, components, OptionProps} from "react-select";
import "./customSelect.scss"
import {ValueContainerProps} from "react-select/dist/declarations/src/components/containers";
import {ArrowIcon} from "../ArrowIcon/ArrowIcon";
import {useState} from "react";

export function CustomSelect<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
    const [width, ] = useState<number>(window.innerWidth);
    const [isEnabled, setIsEnabled] = useState(false)

    const isMobile = width <= 768;
    const ValueContainer = ({ children, ...props }: ValueContainerProps<Option>) => {
        return (
            components.ValueContainer && (
                <components.ValueContainer {...props}>
                    <div className={"value"}>
                        <ArrowIcon fill={"#00000099"} rotate={isEnabled ? "180deg" : "0"}/>
                        {children}
                    </div>
                </components.ValueContainer>
            )
        );
    };

    const OptionCustom = ({ children, ...props }: OptionProps<Option>) => {
        return (
            components.Option && (
                <components.Option {...props} className={"option"}>
                    {children}
                </components.Option>
            )
        );
    };

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            background: "#FFF6E5",
            // match with the menu
            borderRadius: "10px",
            // Overwrittes the different states of border
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            border: "1px solid #C9C1B0",
            radius: "10px"
        }),
        menu: (base: any) => ({
            ...base,
            // override border radius to match the box
            borderRadius: "13px",
            border: "1px solid #C9C1B0",
            background: "#FFF6E5",
            // kill the gap
            marginTop: "7px"
        }),
        menuList: (base: any) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        }),
        option: (base: any, props: OptionProps<Option>) => ({
            ...base,
            fontFamily: "Onest, sans-serif",
            fontSize: "17px",
            fontWeight: "500",
            lineHeight: "21.67px",
            textAlign: "left",
            background: props.isSelected ? "#FFDE9D" : props.isFocused ? "#FFDE9D66" : "#FFF6E5",
            color: "#00000099",
            borderBottom: "1px solid #C9C1B0",
            ':active': {
                backgroundColor: '#FFDE9DAA',
            },
            ':last-child': {
                border: 0,
                borderBottomLeftRadius: "13px",
                borderBottomRightRadius: "13px",
            },
            ':first-child': {
                borderTopLeftRadius: "13px",
                borderTopRightRadius: "13px",
            }
        }),
        singleValue: (base: any) => ({
            fontFamily: "Onest, sans-serif",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "21.67px",
            color: "#00000099",
        })
    };

    return (
        <Select
            // unstyled
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ValueContainer, Option: OptionCustom }}
            // className={"select"}
            onMenuOpen={() => setIsEnabled(true)}
            onMenuClose={() => {
                if (!isMobile) {
                    setIsEnabled(false)
                }
            }}
            // closeMenuOnSelect={false}
            {...props}
            styles={customStyles}
        />
    );
}

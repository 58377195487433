import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { GetHike, Hike, HikeRegistry } from '../types/hike';
import {convertISOToDate} from "../utils/dates/iso-to-date";

const BASE_API_URL = process.env.REACT_APP_BASE_URL;

export const commonApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHikeById: builder.query<Hike, string>({
      query: (id) => `hikes/${id}`,
    }),
    getHikes: builder.mutation<HikeRegistry, GetHike>({
      query: (data) => ({
        url: '/hikes/search',
        method: 'POST',
        body: {...data, dateFrom: convertISOToDate(data.dateFrom), dateTo: convertISOToDate(data.dateTo)},
      }),
    }),
  }),
});

export const { useGetHikeByIdQuery, useGetHikesMutation } = commonApi;

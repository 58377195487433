import React from "react";
import "./CustomHeader.scss"
import {ReactDatePickerCustomHeaderProps} from "react-datepicker";
import { getMonth, getYear } from 'date-fns';
import {ArrowIcon} from "../ArrowIcon/ArrowIcon";



export const CustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const {
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    } = props;
    const year = getYear(date);
    const month = getMonth(date);
    const months = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ];

    return (
        <div className={"container"}>
            <div className={"date-text"}>{months[month]} {year}</div>
            <div className={"arrows"}>
                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <ArrowIcon rotate={"90deg"} fill={"#596F2B"}/>
                </button>
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <ArrowIcon rotate={"-90deg"} fill={"#596F2B"}/>
                </button>
            </div>
        </div>
    )
}

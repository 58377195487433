import React from 'react';
import './Layout.scss';
import { Outlet } from 'react-router-dom';

export function Layout() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default Layout;

import React, {useCallback, useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import { Hike } from '../../types/hike';
import './Hikes.scss';
import { useGetHikesMutation } from '../../store/commonApi';
import { useAppDispatch } from '../../hooks/dispatch';
import { useAppSelector } from '../../hooks/selectors';
import {changeHikes, changePagination, setIsLoading} from "../../store/reducers";
import { ReactComponent as LogotypeBeta } from "../../utils/svg/logotype-beta.svg";
import { ReactComponent as Bicyclist } from "../../utils/svg/bicyclist.svg";
import HikeModal from "../../components/hikeModal/HikeModal";
import HikeList from "../../components/HikeList/HikeList";
import {SearchWithFilters} from "../../components/searchWithFilters/searchWithFilters";

function Hikes() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedHikeId = searchParams.get('id');
  const [show, setShow] = useState(!!selectedHikeId);
  const [scrollPosition, setScrollPosition] = useState(0);
  const dispatch = useAppDispatch();


  const filter = useAppSelector((state) => state.common.filter);
  const pagination = useAppSelector((state) => state.common.pagination);
  const hikes = useAppSelector((state) => state.common.hikes);
  const [mutation] = useGetHikesMutation();
  const isLoading = useAppSelector((state) => state.common.isHikesLoading);

  const onScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 400 || isLoading) {
      return;
    }
    dispatch(
      changePagination({
        ...pagination,
        currentPage: pagination.currentPage + 1,
      }),
    );
  };

  useEffect(() => {
    if (pagination.currentPage <= pagination.totalPages) {
      dispatchRequest();
    }
  }, [pagination.currentPage]);

  const dispatchRequest = useCallback(() => {
    dispatch(setIsLoading(true));
    mutation({
      ...filter,
      searchParameters: {
        ...filter.searchParameters,
        page: pagination.currentPage,
        itemsPerPage: pagination.itemsPerPage,
        orderBy: 0,
      },
    }).then((response) => {
      if ('data' in response) {
        dispatch(changeHikes(pagination.currentPage === 1 ? response.data.hikes : hikes?.concat(response.data.hikes)));

        if ('itemsCount' in response.data) {
          dispatch(changePagination({
            ...pagination,
            totalPages: Math.ceil(response.data.itemsCount / pagination.itemsPerPage),
          }))
        }
      }
      dispatch(setIsLoading(false));
    });
  }, [
    dispatch,
    filter,
    mutation,
    pagination.currentPage,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isLoading])

  const handleClose = () => {
    setSearchParams({});
    setShow(false);
    window.scrollTo({ top: scrollPosition });
  };

  const handleShow = (hike: Hike) => {
    setSearchParams({ id: hike.id });
    setShow(true);
    setScrollPosition(window.scrollY);
  };

  return (
      <main className="hikes-page">
        <header className={'hikes-header'}>
          <Link to={'/home'}>
            <LogotypeBeta className="logotype-header"/>
          </Link>
          {/* eslint-disable-next-line */}
          {/*<a className={'saved-button'} href={'#'}>*/}
          {/*  <svg width="21" height="23" viewBox="0 0 21 23" fill="none"*/}
          {/*       xmlns="http://www.w3.org/2000/svg">*/}
          {/*    <path fill-rule="evenodd" clip-rule="evenodd"*/}
          {/*          d="M17.7837 22.1532L15.609 21.9911C15.609 21.9911 17.758 2.84882 17.9423 1.40377C18.1267 -0.0412808 20.404 0.0953508 20.2943 1.50335C20.1847 2.91135 17.7837 22.1532 17.7837 22.1532ZM8.18666 12.376L9.148 2.96693L16.281 2.89282L15 13.74L8.18666 12.376ZM0.852997 12.4987L4.689 9.05051L0.955663 5.47261L7.328 5.0604L6.628 13.997L0.852997 12.4987Z"*/}
          {/*          fill="black"/>*/}
          {/*  </svg>*/}
          {/*  <span className={'saved-button--label'}>Сохранённые</span>*/}
          {/*</a>*/}
        </header>
        <section className={'search-section'}>
          <Link className={'back-button'} to={'/home'}>
            <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.6854 1.08105L2.31079 11.4557L12.6854 21.8303" stroke="black"
                    strokeWidth="2.65541"/>
              <path d="M2.599 11.4557H27.0946" stroke="black" strokeWidth="2.65541"/>
            </svg>
            <span className={'back-button--label'}>Назад</span>
          </Link>
          <SearchWithFilters onFilterChange={dispatchRequest}/>
        </section>
        <section className={'results-section'}>
          <h3 className={'results-title'}>Результаты поиска</h3>
          <HikeList handleShow={handleShow} hikes={hikes} isLoading={isLoading} pagination={pagination}/>
        </section>
        <section className={'end-section'}>
          <Bicyclist className="bicyclist-svg"/>
          <h2 className={'end-title'}>Всё, что нашли</h2>
          <span className={'end-description description'}>
                    Если у вас не получилось найти то, что искали,
                    попробуйте вписать в поисковик другой запрос.<br/>
                    И не забудьте про фильтры :-)
                </span>
        </section>
        <footer className={'hikes-footer'}>
          <div className={'footer-left'}>
            <LogotypeBeta className={'logotype-footer'}/>
            <span className={'rights'}>© 2024 Lesnik Apps. Все права защищены</span>
            <span className={'icon-usage'}>На сайте используются иконки Антона Чикина</span>
          </div>
          <nav className={'footer-navigation'}>
            <a href={'/home#about'}>О проекте</a>
            {/* eslint-disable-next-line */}
            <a href="https://pay.cloudtips.ru/p/8e1cd0a6" target="_blank">Поддержать</a>
            {/* eslint-disable-next-line */}
            <a href="https://tally.so/r/3qOaoO" target="_blank">Оставить отзыв</a>
          </nav>
        </footer>
        {selectedHikeId && <HikeModal handleClose={handleClose} hikeId={selectedHikeId} show={show}/>}
      </main>
  );
}

export default Hikes;

import React from "react";
import {Hike, Pagination} from '../../types/hike';
import "./HikeList.scss"
import {HikeCard, LoadingHikeCard} from "../hikeCard/HikeCard";

interface HikeListProps {
  handleShow: (hike: Hike) => void;
  hikes?: Array<Hike>;
  isLoading: boolean;
  pagination: Pagination;
}

function HikeList(props: HikeListProps) {
  const { handleShow, hikes, isLoading, pagination } = props;
  const mockHikes = Array.from(Array(12).keys()).map(() => <LoadingHikeCard />)


  return (
    <div className="results-container">
      {
        pagination.currentPage === 1 && isLoading ? mockHikes :
        hikes?.map((hike) => (
            <HikeCard hike={hike} key={hike.id} handleShow={handleShow} />
          )).concat(isLoading ? mockHikes : [])
      }
    </div>
  );
}

export default HikeList;

import "./searchWithFilters.scss"
import {FiltersModal} from "../filtersModal/filtersModal";
import {useAppDispatch} from "../../hooks/dispatch";
import {useAppSelector} from "../../hooks/selectors";
import {changeFilter, changePagination, setIsLoading} from "../../store/reducers";
import {useDebounce} from "../../hooks/useDebounce";
import {useEffect} from "react";
import pagination from "../pagination/Pagination";


export const SearchWithFilters = (props: {onFilterChange: () => void}) => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(state => state.common.filter)

    const debouncedDispatchRequest = useDebounce(() => {
        props.onFilterChange();
    }, 500);

    useEffect(() => {
        dispatch(changePagination({
            ...pagination,
            currentPage: 1,
        }));
        dispatch(setIsLoading(true));
        debouncedDispatchRequest();
    }, [dispatch, filters.description]);

    return (
        <div className="search-filters-container">
            <div className="input-wrapper">
                <input placeholder="Введите название места" value={filters.description} onChange={(e) => {
                    dispatch(changeFilter({...filters, description: e.target.value}))
                }}/>
                <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6723 21.7852L10.8482 18.0274L15.4949 16.0829L17.4521 19.7381C18.7223 22.7102 13.8013 24.4945 12.6723 21.7852ZM5.73024 1.16744C15.9263 -3.01603 22.4396 11.9887 12.4805 16.075C1.57887 20.5501 -4.50839 5.3693 5.73024 1.16744ZM6.56904 3.13829C-0.990776 6.24173 3.49611 17.4493 11.5539 14.1435C18.9086 11.1242 14.1022 0.045368 6.56904 3.13829Z" fill="black" fillOpacity="0.6"/>
                </svg>
            </div>
            <FiltersModal onFilterChange={() => {
                dispatch(changePagination({
                    ...pagination,
                    currentPage: 1,
                }));
                dispatch(setIsLoading(true));
                debouncedDispatchRequest()
            }}/>
        </div>
    )
}

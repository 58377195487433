import "./filterModal.scss"
import {CustomSelect} from "../customSelect/customSelect";
import {CustomButton} from "../customButton";
import React, {useState} from "react";
import {ButtonFiltersModal} from "../buttonsFilterModal/buttonFiltersModal";
import {CustomCalendar} from "../customCalendar/CustomCalendar";
import {useAppSelector} from "../../hooks/selectors";
import {useAppDispatch} from "../../hooks/dispatch";
import {changeFilter, closeFilter, openFilter, resetFilters} from "../../store/reducers";
import {getClosestWeekendFromDate} from "../../utils/dates/closest-weekends";
import {HikeTypeMap} from "../consts/hikeTypeMap";
import {getClassnames} from "../../utils/css";


export const FiltersModal = (props: {onFilterChange: () => void}) => {
    const options = HikeTypeMap;
    const weekends = getClosestWeekendFromDate(new Date())
    const [isWeekends, setIsWeekends] = useState(false)

    const dispatch = useAppDispatch();
    const isFilterOpen = useAppSelector(state => state.common.isFilterOpen)
    const filters = useAppSelector(state => state.common.filter)

    return (
        <div className="filter-modal-wrapper">
            <div className={getClassnames('close-area', !isFilterOpen ? 'closed' : '')} onClick={() => {
                props.onFilterChange();
                dispatch(closeFilter())
            }}/>
            <div className={isFilterOpen ? "closed-button" : "visible-button"}>
                <CustomButton className={"filter-open-button-container"} onClick={() => dispatch(openFilter())}>
                    <svg width="17" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M6.71827 24.9852C5.63327 23.8757 2.9094 20.8884 2.9094 18.8484C2.9094 16.1115 4.7694 15.7936 4.7694 15.7936L5.48654 18.0673L6.13134 17.8126C6.11894 14.5368 6.0962 10.0042 6.04867 7.90101C6.0094 6.19154 8.84074 5.94101 8.90274 7.8547C8.93374 8.79154 9.0784 11.5263 9.23134 13.9179C11.8002 14.1158 14.7514 15.2358 16.2167 16.8421C15.7289 18.8989 15.1544 22.8442 14.9767 24.04L6.71827 24.9852ZM11.5026 8.78944L11.484 7.67786L14.9395 7.53259L14.9271 9.05891L11.5026 8.78944ZM9.469 4.57891L11.6349 2.00417L12.786 3.02522L10.3783 5.4568L9.469 4.57891ZM5.19514 0.65891L6.60667 0.395752L7.02 3.9747L5.88954 4.07575L5.19514 0.65891ZM0.392205 5.43575L1.06801 4.08838L4.01094 5.79996L3.55214 6.8168L0.392205 5.43575ZM3.8084 10.3663L0.627805 11.6568L0.183472 10.1347L3.4116 9.34101L3.8084 10.3663Z"
                              fill="#131918" fillOpacity="0.6"/>
                    </svg>
                    Укажите фильтры
                </CustomButton>
                <div className={"text"}>На основе фильтров алгоритм лучше
                    подберёт подходящие походы</div>
            </div>
            <div className={getClassnames('filter-modal-container', !isFilterOpen ? "closed" : '')}>
                <ButtonFiltersModal/>
                <CustomSelect placeholder={"Выберите тип"} options={options}
                              value={options.filter(x => x.value === filters.hikeType)} onChange={(newValue) => {
                    dispatch(changeFilter({...filters, hikeType: newValue?.value}));
                }}/>
                <div className="calendar-section">
                    <CustomButton type={isWeekends ? "accept" : "default"} onClick={() => {
                        if (!isWeekends) {
                            dispatch(changeFilter({
                                ...filters,
                                dateFrom: weekends.saturday.toISOString(),
                                dateTo: weekends.sunday.toISOString(),
                            }))
                        } else {
                            dispatch(changeFilter({
                                ...filters,
                                dateFrom: (new Date()).toISOString(),
                                dateTo: undefined,
                            }))
                        }
                        setIsWeekends(!isWeekends);
                    }}>
                        Ближайшие выходные
                    </CustomButton>
                    <CustomCalendar startDate={new Date(filters.dateFrom ?? "")}
                                    endDate={filters.dateTo ? new Date(filters.dateTo) : undefined}
                                    onChange={(date) => {
                                        setIsWeekends(false);
                                        dispatch(changeFilter({
                                            ...filters,
                                            dateFrom: date[0]?.toISOString(),
                                            dateTo: date[1]?.toISOString(),
                                        }))
                                    }}
                    />
                </div>
                <div className="buttons">
                    <CustomButton type={"warning"} onClick={() => {
                        setIsWeekends(false);
                        dispatch(resetFilters())
                    }}>
                        Сбросить
                    </CustomButton>
                    <CustomButton type={"accept"} onClick={() => {
                        props.onFilterChange()
                        dispatch(closeFilter())
                    }}>
                        Выбрать
                    </CustomButton>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import defaultStyles from './styles/default.module.scss'

import {buttonStyleDictionary, CustomButtonProps, typeButton} from './interfaces'
import classNames from 'classnames'

export const CustomButton: React.FC<CustomButtonProps> = (props) => {
    const { type = 'default', className, ...restProps } = props

    if (!typeButton.includes(type)) {
        return (
            <button
                className={classNames(defaultStyles.button, className)}
                {...restProps}
                data-testid='btn'
            />
        )
    } else {
        return (
            <button
                className={classNames(buttonStyleDictionary[type]?.button, className)}
                {...restProps}
                data-testid='btn'
            />
        )
    }
}

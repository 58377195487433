import './PrivacyPolicy.scss';
import React from "react";

function PrivacyPolicy() {
    return (
        <body>
        <div className={'policy-container'}>
            <h1>Политика конфиденциальности</h1>
            <div className='list-container'>
                <ul>
                    Пожалуйста, внимательно ознакомьтесь с этой политикой, чтобы
                    понять наши практики в отношении обработки информации.
                </ul>

                <h3>Использование информации</h3>
                <ul>
                    Мы используем информацию о походах из открытых источников для
                    предоставления сервиса поиска и публикации информации о походах на
                    нашем сайте.
                </ul>

                <h3>Ограничение ответственности</h3>
                <ul>
                    Мы не несем ответственности за достоверность и полноту информации о
                    походах, так как эта информация берется из открытых источников. Также мы не несем ответственности
                    за участников походов и сами походы
                </ul>

                <h3>Сбор информации</h3>
                <ul>
                    Мы собираем обезличенные данные об использовании сайта с помощью сервисов Яндекс.Метрика
                </ul>

                <h3>Безопасность</h3>
                <ul>
                    Мы предпринимаем разумные меры для защиты предоставляемой информации от
                    несанкционированного доступа, использования или подмены.
                </ul>

                <h3>Согласие с политикой</h3>
                <ul>
                    Используя наш сервис, вы выражаете свое согласие с данной
                    политикой конфиденциальности. Если вы не согласны с условиями,
                    пожалуйста, воздержитесь от использования сервиса.
                </ul>

                <h3>Изменения политики конфиденциальности</h3>
                <ul>
                    Мы оставляем за собой право изменять эту политику
                    конфиденциальности. Любые изменения будут опубликованы на нашем
                    веб-сайте. Регулярно проверяйте политику конфиденциальности, чтобы
                    быть в курсе изменений.
                </ul>

                <ul>
                    Если у вас есть какие-либо вопросы или требуется дополнительная
                    информация о нашей политике конфиденциальности, пожалуйста,
                    свяжитесь с нами через раздел Оставить отзыв.
                </ul>
            </div>
        </div>
        </body>
    );
}

export default PrivacyPolicy;

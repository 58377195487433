import { commonApi } from './commonApi';
import {combineReducers, createSlice} from '@reduxjs/toolkit';
import { CommonSchema } from './types';

const initialFilterState = {
  searchParameters: {
    page: 0,
    orderBy: 0,
    orderAsc: true,
  },
  description: '',
  dateFrom: new Date().toISOString().split('T')[0],
};

const initialPaginationState = {
  currentPage: 1,
  totalPages: 0,
  itemsPerPage: 12,
};

const initialState: CommonSchema = {
  filter: initialFilterState,
  isFilterOpen: false,
  showDevelopingToast: false,
  isHikesLoading: true,
  hikes: [],
  pagination: initialPaginationState,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filter = action.payload;
      state.pagination = initialPaginationState;
    },
    openFilter: (state) => {
      state.isFilterOpen = true;
    },
    closeFilter: (state) => {
      state.isFilterOpen = false;
    },
    showDevelopingToast: (state, action) => {
      state.showDevelopingToast = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isHikesLoading = action.payload;
    },
    resetFilters: (state) => {
      state.filter = initialFilterState;
    },
    changePagination: (state, action) => {
      state.pagination = action.payload;
    },
    changeHikes: (state, action) => {
      state.hikes = action.payload;
    },
    clearHikes: (state) => {
      state.hikes = [];
    },
}});

const rootReducer = combineReducers({
  common: commonSlice.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
});

export const {changeHikes, changeFilter, openFilter, closeFilter, showDevelopingToast, setIsLoading,resetFilters,changePagination} = commonSlice.actions
export default rootReducer;

import { Hike } from '../../types/hike';
import './HikeCard.scss';
import { Placeholder } from 'react-bootstrap';
import Figure from 'react-bootstrap/Figure';
import React from "react";
import {HikeTypeMap} from "../consts/hikeTypeMap";

interface HikeCardProps {
  hike: Hike;
  handleShow: (hike: Hike) => void;
}

export function LoadingHikeCard() {
  return (
      <div className={'hike-card'}>
          <div className={'card-img-wrapper'}>
              <Figure
                          className='card-img card-img-top image_hover'
                          style={{ backgroundColor: 'gray' }}
              >
                  <Figure.Image width={'100%'} height={'100%'} />
              </Figure>
          </div>
          <div className={'card-content'}>
              <h5 className={'card-title'}><Placeholder xs={12} /></h5>
              <div className={'tag-container'}>
                  <div className={'tag tag__date'} style={{width: "50px"}}>
                      <span className={'tag--label'}><Placeholder xs={6} /></span>
                  </div>
                  <div className={'tag tag__duration'} style={{width: "50px"}}>
                      <span className={'tag--label'}><Placeholder xs={6} /></span>
                  </div>
                  <div className={`tag tag__cost tag__cost_paid`} style={{width: "50px"}}>
                      <span className={'tag--label'}><Placeholder xs={6} /></span>
                  </div>
                  <div className={'tag tag__type'} style={{width: "50px"}}>
                      <span className={'tag--label'}><Placeholder xs={6} /></span>
                  </div>
              </div>
          </div>
          <button className={'more-info-button'}>
              <Placeholder xs={6} />
          </button>
      </div>
  );
}

export function HikeCard(props: HikeCardProps) {
  const { hike, handleShow } = props;
  const hikeType = HikeTypeMap.filter(x => hike.hikeType === x.value);

  return (
      <div className={'hike-card'} onClick={() => handleShow(hike)}>
          <div className={'card-img-wrapper'}>
              <img src={hike.imageUrlLowRes} className={'card-img'} alt={'hike'}/>
          </div>
          <div className={'card-content'}>
              <h5 className={'card-title'}>{hike.name}</h5>
              <div className={'tag-container'}>
                  {hike.date && <div className={'tag tag__date'}>
                      <span className={'tag--label'}>{new Date(hike.date).toLocaleString('ru-RU', {day: 'numeric', month: 'long'})}</span>
                  </div>}
                  {hike.isOneDayTrip !== undefined && <div className={'tag tag__duration'}>
                      <span className={'tag--label'}>{hike.isOneDayTrip ? "Сутки" : "Многодневный"}</span>
                  </div>}
                  {hike.isCommercial !== undefined && <div className={`tag tag__cost ${hike.isCommercial ?  "tag__cost_paid" : 'tag__cost_free'}`}>
                      <span className={'tag--label'}>{hike.isCommercial ? "Платный" : "Бесплатный"}</span>
                  </div>}
                  {hikeType && <div className={'tag tag__type'}>
                      <span className={'tag--label'}>{hikeType[0].label}</span>
                  </div>}
              </div>
          </div>
          <button className={'more-info-button'} onClick={() => handleShow(hike)}>
              Подробнее
          </button>
      </div>
  );
}

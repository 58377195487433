export function convertISOToDate(isoString: string | undefined) {
    if (isoString === undefined)
        return undefined
    // Create a new Date object from the ISO string
    const date = new Date(isoString);

    // Extract the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    // Combine into the desired format
    return `${year}-${month}-${day}`;
}

import React from 'react';
import { Link } from 'react-router-dom';

function Page404() {
  return (
    <body>
      <div className='d-flex align-items-center justify-content-center vh-100 navbar-dark bg-dark'>
        <div className='d-flex align-items-center justify-content-center vh-100 text-white'>
          <div className='text-center'>
            <h1 className='display-1 fw-bold'>404</h1>
            <p className='fs-3'>
              <span className='text-danger'>Упс!</span> Страница не найдена.
            </p>
            <p className='lead'>
              Страницы, которую вы пытаетесь найти, не существует.
            </p>
            <Link className='btn btn-outline-success' to='/'>
              На главную
            </Link>
          </div>
        </div>
      </div>
    </body>
  );
}

export default Page404;

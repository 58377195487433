import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes, useNavigate} from 'react-router-dom';
import Layout from './components/layouts/Layout/Layout';
import Page404 from './pages/404/404';
import {Provider} from 'react-redux';
import store from './store/store';
import PrivacyPolicy from "./pages/privacy_policy/PrivacyPolicy";
import Hikes from "./pages/hikes/Hikes";
import Home from "./pages/home/Home";

function RedirectComponent({ url }: { url: string }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(url);
  });

  return null;
}

function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index path='' element={<RedirectComponent url={'home'} />} />
          <Route index path='home' element={<Home />} />
          <Route index path='hikes' element={<Hikes />} />
          <Route index path={'privacy-policy'} element={<PrivacyPolicy/>} />
        </Route>
        <Route path='*' element={<Page404 />} />
      </Routes>
    </Provider>
  );
}

export default App;

export function getClosestWeekendFromDate(date: Date) {
    const inputDate = new Date(date);
    const dayOfWeek = inputDate.getDay(); // 0 (Sunday) to 6 (Saturday)

    let saturday, sunday;

    if (dayOfWeek === 0) { // Sunday
        saturday = new Date(inputDate);
        saturday.setDate(inputDate.getDate() - 1); // Previous day
        sunday = inputDate;
    } else if (dayOfWeek === 6) { // Saturday
        saturday = inputDate;
        sunday = new Date(inputDate);
        sunday.setDate(inputDate.getDate() + 1); // Next day
    } else {
        saturday = new Date(inputDate);
        saturday.setDate(inputDate.getDate() + (6 - dayOfWeek)); // Next Saturday
        sunday = new Date(inputDate);
        sunday.setDate(inputDate.getDate() + (7 - dayOfWeek)); // Next Sunday
    }

    return {
        saturday: saturday,
        sunday: sunday
    };
}

import "./buttonsFiltersModal.scss"
import {CustomButton} from "../customButton";
import {useAppSelector} from "../../hooks/selectors";
import {useAppDispatch} from "../../hooks/dispatch";
import {changeFilter} from "../../store/reducers";

export const ButtonFiltersModal = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(state => state.common.filter)

    const FilterButton = ({boolValue, reduxKey, activeBool, text}: {
        boolValue?: boolean,
        activeBool: boolean
        reduxKey: string,
        text: string
    }) => (
        <CustomButton type={boolValue === undefined || boolValue !== activeBool ? "default-green" : "accept"}
                      onClick={() => dispatch(changeFilter({
                          ...filters,
                          [reduxKey]: boolValue === activeBool ? undefined : activeBool
                      }))}>
            {text}
        </CustomButton>
    )

    return <div className="button-filters-container">
        <div className="one-section">
            <FilterButton boolValue={filters.isOneDayTrip} activeBool={true} reduxKey={"isOneDayTrip"}
                          text={"На один день"}/>
            <FilterButton boolValue={filters.isOneDayTrip}
                          activeBool={false} reduxKey={"isOneDayTrip"} text={"На два и больше"}/>
        </div>
        <div className="one-section">
            <FilterButton boolValue={filters.isCommercial} activeBool={true} reduxKey={"isCommercial"}
                          text={"Платный"}/>
            <FilterButton boolValue={filters.isCommercial} activeBool={false} reduxKey={"isCommercial"}
                          text={"Бесплатный"}/>
        </div>
    </div>
}
